<!--
 * @Author: gaojingran
 * @Date: 2021-04-19 09:37:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-17 14:14:19
 * @Description: 报价单
-->
<style lang="less" scoped>
.quotation_link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.quotation {
  .quotation-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .logo {
      width: 84px;
      height: 42px;
      background-image: url('~@/assets/image/pactera-logo.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .title {
      font-size: 0;
      .date,
      .text {
        display: inline-block;
        vertical-align: middle;
      }
      .text {
        font-size: @font_size_6;
        color: #000000;
        font-weight: bold;
      }
      .date {
        padding-left: 5px;
        font-size: @font_size_2;
        color: #000000;
      }
    }
  }

  .info-item {
    display: flex;
    .label {
      flex: 0 0 80px;
      width: 80px;
      text-align: right;
      font-size: @font_size_2;
      color: #000000;
    }
    .desc {
      flex: 1;
      width: 0;
      margin-left: 10px;
      font-size: @font_size_2;
      color: #000000;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .quotation-type {
    display: flex;
  }
}
.c-1,
.c-2,
.success {
  color: #000000;
}
</style>

<template>
  <NeoDrawerLayout :showFooter="!isPdf" :useOriginalScroll="isPdf">
    <a-spin :spinning="loading" style="height: 100%">
      <div class="quotation">
        <div class="quotation-title">
          <div class="logo"></div>
          <div class="title">
            <span class="text">{{ $t('orderInfo.quote') }}</span>
            <span class="date" v-if="reportInfo.quoteDate">{{ reportInfo.quoteDate | dateFormat }}</span>
          </div>
          <div class="extra">
            <template v-if="!isPdf">
              <a-button type="primary" :disabled="reportInfo.status === 1" :loading="pdfLoading" @click="handleExport">
                {{ $t('export') }}
              </a-button>
            </template>

            <NeoTag v-if="isPdf && ids.length < 2" shape type="pure-bw">{{
              localeDict.orderStatus[Math.min(9, reportInfo.status)] | defined
            }}</NeoTag>
          </div>
        </div>
        <!-- 报价单详情 -->
        <!-- <NeoTitle class="mb-3" text="123123" /> -->
        <NeoTitle class="mb-3 pure-bw" :text="$t('orderInfo.quote_info')" />
        <a-row :gutter="20">
          <a-col :span="24" class="info-item mb-3" v-if="ids.length < 2">
            <span class="label">{{ $t('orderInfo.quote_title') }}:</span>
            <span class="desc">{{ reportInfo.code | defined }} / {{ reportInfo.name | defined }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('orderInfo.quote_customer') }}:</span>
            <span class="desc">{{ reportInfo.cusAgencyName | defined }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('orderInfo.quote_provider') }}:</span>
            <span class="desc">{{ reportInfo.comAgencyName | defined }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('orderInfo.quote_address') }}:</span>
            <span class="desc">{{ reportInfo.cusAgencyAddress | defined }}</span>
          </a-col>
          <a-col :span="12" class="info-item mb-3">
            <span class="label">{{ $t('orderInfo.quote_address') }}:</span>
            <span class="desc">{{ reportInfo.comAgencyAddress | defined }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12" class="info-item mb-3" v-if="ids.length < 2">
            <span class="label">{{ $t('orderInfo.quote_contact') }}:</span>
            <span class="desc">
              {{ reportInfo.cusManager | defined }} ({{ reportInfo.cusManagerEmail | defined }})
            </span>
          </a-col>
          <a-col :span="12" class="info-item mb-3" v-if="ids.length < 2">
            <span class="label">{{ $t('orderInfo.quote_contact') }}:</span>
            <span class="desc">
              {{ reportInfo.comManager | defined }} ({{ reportInfo.comManagerEmail | defined }})
            </span>
          </a-col>
        </a-row>
        <!-- 普通导出时候的表格 -->
        <a-table
          class="no-br pure-bw"
          size="middle"
          rowKey="id"
          v-if="ids.length < 2"
          :data-source="dataSource"
          :pagination="false"
          :bordered="true"
        >
          <a-table-column
            key="serviceType"
            :width="getWidth(100)"
            :ellipsis="true"
            :title="$t('orderInfo.table_serviceType')"
          >
            <template slot-scope="text">
              <span>{{ text.serviceLabel }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="sourceCode"
            :width="getWidth('14.6%')"
            :ellipsis="true"
            :title="$t('orderInfo.table_source')"
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template slot-scope="text">
              <span :title="$store.getters['app/getLangNameByCode'](text.sourceCode) | defined">{{
                $store.getters['app/getLangNameByCode'](text.sourceCode) | defined
              }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="targetCode"
            :width="getWidth('14.6%')"
            :ellipsis="true"
            :title="$t('orderInfo.table_target')"
          >
            <template slot-scope="text">
              <span :title="$store.getters['app/getLangNameByCode'](text.targetCode) | defined">
                {{ $store.getters['app/getLangNameByCode'](text.targetCode) | defined }}</span
              >
            </template>
          </a-table-column>

          <a-table-column
            key="price"
            align="right"
            :ellipsis="true"
            :width="getWidth(120)"
            :title="$t('orderInfo.table_price')"
          >
            <template slot-scope="text">
              <!-- 当等待报价 & 没有selectivePriceList时显示尚未报价 -->
              <template
                v-if="
                  (!$is.Defined(text.selectivePriceList) || !text.selectivePriceList.length) &&
                  info.status === 1 &&
                  noProjManText(text)
                "
              >
                <!-- 尚未报价 -->
                <span>
                  --
                  <a-tooltip class="warning" placement="top" v-if="noProjManText(text)">
                    <template v-slot:title>
                      <span class="quotation_link" @click="handleAddPrice(text)" v-if="info.isOrderSpm === 1">
                        {{ $t('orderInfo.quote_no_price') }}
                      </span>
                      <span v-else>{{ $t('orderInfo.quote_no_price') }}</span>
                    </template>
                    <a-icon type="exclamation-circle" theme="filled" />
                  </a-tooltip>
                </span>
              </template>
              <template v-else>
                <!-- 供应商可在等待报价阶段修改单价 -->
                <template v-if="$role('order_orderInfo_createQuote') && info.status === 1 && noProjManText(text)">
                  <a-select
                    v-model="text.priceType"
                    :placeholder="$t('orderInfo.quote_init_price_holder')"
                    size="small"
                    style="display: block"
                  >
                    <a-select-option v-for="item in text.selectivePriceList" :key="item.id" :value="item.id">
                      <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
                      <span>{{ item.price }}</span> /
                      <span>{{ $store.getters['app/getDictLabel']('SALE_UNIT', item.unit) | defined }}</span>
                    </a-select-option>
                  </a-select>
                </template>
                <template v-else>
                  <template v-if="text.serviceKind === 3">
                    <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
                    <span>{{ text.price }}</span>
                  </template>
                  <template v-else>
                    <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
                    <span>{{ text.price }}</span> /
                    <span>{{ $store.getters['app/getDictLabel']('SALE_UNIT', text.unit) | defined }}</span>
                  </template>
                </template>
              </template>
            </template>
          </a-table-column>

          <a-table-column
            key="amount"
            align="right"
            :ellipsis="true"
            :width="getWidth(80)"
            :title="$t('orderInfo.table_number')"
          >
            <template slot-scope="text">
              <span>{{ text.amount }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="wordAmount"
            align="right"
            :width="getWidth(80)"
            :title="$t('orderInfo.table_totalNumber')"
          >
            <template slot-scope="text">
              <span>{{ text.wordAmount }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="totalPrice"
            align="right"
            :ellipsis="true"
            :width="getWidth(90)"
            :title="$t('orderInfo.table_totalPrice')"
          >
            <template slot-scope="text, _, index">
              <span v-if="!$is.Defined(text.priceType) && info.status === 1 && noProjManText(text)">--</span>
              <span v-else>
                <div class="_flex" style="justify-content: flex-end">
                  <!-- <span class="_mr-2" v-if="currencyUnit()">{{ currencyUnit()[1] }}</span> -->
                  <template v-if="noProjManText(text)">
                    <QuotationItem
                      :isPdf="isPdf"
                      :text="getPriceByAmount(index)"
                      :showUnit="currencyUnit()"
                      :currencyUnit="currencyUnit()[1]"
                    />
                  </template>
                  <template v-else>
                    <QuotationItem
                      :isPdf="isPdf"
                      :text="proAdminCost"
                      :showUnit="currencyUnit()"
                      :currencyUnit="currencyUnit()[1]"
                    />
                  </template>
                </div>
              </span>
            </template>
          </a-table-column>

          <!-- 删除单条报价 & 仅可删除服务类型2开头的 -->
          <a-table-column
            v-if="$role('order_orderInfo_createQuote') && info.status === 1 && !isPdf"
            key="action"
            align="right"
            :width="80"
            :title="$t('action')"
          >
            <template slot-scope="text, _, index">
              <a-popconfirm
                v-if="text.serviceKind === 2 && !text.no_operate"
                placement="left"
                :title="$t('prompt.info', { handle: $t('delete') })"
                :ok-text="$t('confirm')"
                :cancel-text="$t('cancel')"
                @confirm="
                  () => {
                    handeDeletePriceItem(index)
                  }
                "
              >
                <a>{{ $t('delete') }}</a>
              </a-popconfirm>
            </template>
          </a-table-column>

          <template slot="footer">
            <a-row type="flex" align="middle" justify="space-between">
              <div>
                <span class="c-1">{{ $t('orderInfo.table_footer_label') }}:</span>
                <span class="c-2 pl-1">{{ $t('orderInfo.table_footer_desc') }}</span>
              </div>
              <div>
                <span class="fs-3 c-1 bold">{{ $t('orderInfo.table_footer_priceTotal') }}:</span>
                <span class="fs-3 primary pl-1">
                  <span v-if="currencyUnit()">{{ currencyUnit()[1] }}</span>
                  <span>{{ getTableTotalPrice2() | countFormat }}</span>
                </span>
              </div>
            </a-row>
          </template>
        </a-table>
        <!-- 批量导出时候的表格 -->
        <a-table
          class="no-br pure-bw"
          size="middle"
          rowKey="id"
          v-if="ids.length >= 2"
          :data-source="batchDataSource"
          :pagination="false"
          :bordered="true"
        >
          <a-table-column key="code" :ellipsis="true" :title="$t('orderInfo.table_order_num')">
            <template slot-scope="text">
              <span>{{ text.code }}</span>
            </template>
          </a-table-column>

          <a-table-column key="name" :ellipsis="true" :title="$t('orderInfo.table_order_name')">
            <template slot-scope="text">
              <span class="line-feed">{{ text.name }}</span>
            </template>
          </a-table-column>

          <a-table-column key="quoteDate" :ellipsis="true" :title="$t('orderInfo.table_order_price_date')">
            <template slot-scope="text">
              <span>{{ moment(text.quoteDate).format($t('dateFormat.b')) | defined }}</span>
            </template>
          </a-table-column>

          <a-table-column key="status" :ellipsis="true" :title="$t('orderInfo.table_order_status')">
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template slot-scope="text">
              <span :class="text.status >= 9 ? 'success' : ''">{{
                localeDict.orderStatus[Math.min(9, text.status)] | defined
              }}</span>
            </template>
          </a-table-column>
          <!-- 下单方经理 -->
          <a-table-column key="cusManager" :ellipsis="true" :title="$t('payment.customer_contacts')">
            <template slot-scope="text">
              <span>{{ text.cusManager | defined }}</span>
            </template>
          </a-table-column>
          <!-- 供应商经理 -->
          <a-table-column key="comManager" :ellipsis="true" :title="$t('payment.provider_contacts')">
            <template slot-scope="text">
              <span>{{ text.comManager | defined }}</span>
            </template>
          </a-table-column>

          <a-table-column
            key="totalPrice"
            align="right"
            :ellipsis="true"
            :width="100"
            :title="$t('orderInfo.table_order_total_price')"
          >
            <template slot-scope="text">
              <span>
                <span v-if="currencyUnit(text.currency)">{{ currencyUnit(text.currency)[1] }}</span>
                {{ text.quotePrice | countFormat }}
              </span>
            </template>
          </a-table-column>

          <template slot="footer">
            <a-row type="flex" align="middle" justify="space-between">
              <div
                v-html="
                  $t('orderInfo.table_order_footer_count', {
                    number: batchDataSource.length,
                  })
                "
              ></div>
              <div>
                <span class="fs-3 c-1 bold">{{ $t('orderInfo.table_footer_priceTotal') }}:</span>
                <span class="fs-3 primary pl-1">
                  <span v-if="currencyUnit(reportInfo.currency)">{{ currencyUnit(reportInfo.currency)[1] }}</span>
                  {{ getBatchDataSourceTotalPrice() | countFormat }}
                </span>
              </div>
            </a-row>
          </template>
        </a-table>
        <div class="quotation-type mt-3" v-if="$role('order_orderInfo_addQuoteOption') && !isPdf && info.status === 1">
          <!-- 更改订单类型 -->
          <a-button class="neo-btn-primary mr-3" @click="handleChangeOrderType">
            {{ $t('orderInfo.btn_quote_changeOrderType') }}
          </a-button>
          <!-- 新增报价项 -->
          <a-button class="neo-btn-primary" @click="handleQuoteOption">
            {{ $t('orderInfo.btn_quote_newOption') }}
          </a-button>
          <!-- 包含项目管理费 -->
          <div>
            <a-checkbox style="margin: 0 10px 0 20px" v-model="containsProjectManageCost"> </a-checkbox>
            {{ $t('newOrder.project_manage_cost_head') }}
            <a-input-number
              style="width: 80px"
              :value="projectManageRatio"
              @change="projectManageRatioInput($event, 'a-input-number-x')"
              :disabled="!containsProjectManageCost"
              :min="0"
              :max="100"
              :precision="0"
              :step="1"
            />
            {{ $t('newOrder.project_manage_cost_tail') }}
          </div>
        </div>

        <!-- wwc 字数明细 -->
        <NeoTitle v-if="ids.length < 2" class="mt-3 pure-bw" :text="$t('orderInfo.quote_wwc_detail')" />
        <!-- 单个订单 -->
        <template v-if="ids.length < 2">
          <a-table
            class="mt-3 pure-bw"
            size="middle"
            rowKey="id"
            :scroll="!isPdf ? { x: 1200 } : {}"
            :data-source="wwcDataSource"
            :pagination="false"
            :bordered="true"
          >
            <a-table-column
              key="targetCode"
              align="center"
              :fixed="!isPdf ? 'left' : null"
              :ellipsis="false"
              :width="180"
              :title="$t('orderInfo.table_lang')"
            >
              <template slot-scope="text">
                <span>{{ $store.getters['app/getLangNameByCode'](text.targetCode) }}</span>
              </template>
            </a-table-column>

            <a-table-column-group>
              <span slot="title">{{ $t('orderInfo.table_matching') }}</span>
              <a-table-column v-for="k in wwcColumn" :key="k" align="center" :title="k">
                <template slot-scope="text">
                  <span>{{ text[k] }}</span>
                </template>
              </a-table-column>
            </a-table-column-group>

            <a-table-column
              key="wwc"
              :fixed="!isPdf ? 'right' : null"
              :ellipsis="false"
              align="center"
              :width="100"
              :title="$t('orderInfo.table_wwc')"
            >
              <template slot-scope="text, _, index">
                <span>{{ getWwcCount(index) }}</span>
              </template>
            </a-table-column>
          </a-table>
        </template>
        <!-- 多个订单 区分版本 -->
        <template v-else>
          <a-table
            v-if="wwcDataSourceVersionA.length"
            class="head-bg mt-3"
            size="middle"
            rowKey="id"
            :scroll="!isPdf ? { x: 1200 } : {}"
            :data-source="wwcDataSourceVersionA"
            :pagination="false"
            :bordered="true"
          >
            <a-table-column
              key="orderCode"
              align="center"
              :fixed="!isPdf ? 'left' : null"
              :ellipsis="false"
              :width="120"
              :title="$t('orderInfo.table_order_num')"
              v-if="ids.length >= 2"
            >
              <template slot-scope="text">
                <span>{{ text.orderCode }}</span>
              </template>
            </a-table-column>

            <a-table-column
              key="targetCode"
              align="center"
              :fixed="!isPdf ? 'left' : null"
              :ellipsis="false"
              :title="$t('orderInfo.table_lang_double')"
              v-if="ids.length >= 2"
            >
              <template slot-scope="text">
                <span>{{
                  `${$store.getters['app/getLangNameByCode'](text.sourceCode)}>${$store.getters[
                    'app/getLangNameByCode'
                  ](text.targetCode)}`
                }}</span>
              </template>
            </a-table-column>

            <a-table-column-group>
              <span slot="title">{{ $t('orderInfo.table_matching') }}</span>
              <a-table-column v-for="k in getWwcTableRangeByProduceVersion(1)" :key="k" align="center" :title="k">
                <template slot-scope="text">
                  <span>{{ text[k] }}</span>
                </template>
              </a-table-column>
            </a-table-column-group>

            <a-table-column
              key="wwc"
              :fixed="!isPdf ? 'right' : null"
              :ellipsis="false"
              align="center"
              :width="100"
              :title="$t('orderInfo.table_wwc')"
            >
              <template slot-scope="text, _, index">
                <span>{{ getBatchWwcCount(1, wwcDataSourceDiscountA, index) }}</span>
              </template>
            </a-table-column>
          </a-table>

          <a-table
            v-if="wwcDataSourceVersionB.length"
            class="head-bg mt-3"
            size="middle"
            rowKey="id"
            :scroll="!isPdf ? { x: 1200 } : {}"
            :data-source="wwcDataSourceVersionB"
            :pagination="false"
            :bordered="true"
          >
            <a-table-column
              key="orderCode"
              align="center"
              :fixed="!isPdf ? 'left' : null"
              :ellipsis="false"
              :width="140"
              :title="$t('orderInfo.table_order_num')"
              v-if="ids.length >= 2"
            >
              <template slot-scope="text">
                <span>{{ text.orderCode }}</span>
              </template>
            </a-table-column>

            <a-table-column
              key="targetCode"
              align="center"
              :fixed="!isPdf ? 'left' : null"
              :ellipsis="false"
              :title="$t('orderInfo.table_lang_double')"
              v-if="ids.length >= 2"
            >
              <template slot-scope="text">
                <span>{{
                  `${$store.getters['app/getLangNameByCode'](text.sourceCode)}>${$store.getters[
                    'app/getLangNameByCode'
                  ](text.targetCode)}`
                }}</span>
              </template>
            </a-table-column>

            <a-table-column-group>
              <span slot="title">{{ $t('orderInfo.table_matching') }}</span>
              <a-table-column v-for="k in getWwcTableRangeByProduceVersion(2)" :key="k" align="center" :title="k">
                <template slot-scope="text">
                  <span>{{ text[k] }}</span>
                </template>
              </a-table-column>
            </a-table-column-group>

            <a-table-column
              key="wwc"
              :fixed="!isPdf ? 'right' : null"
              :ellipsis="false"
              align="center"
              :width="100"
              :title="$t('orderInfo.table_wwc')"
            >
              <template slot-scope="text, _, index">
                <span>{{ getBatchWwcCount(2, wwcDataSourceDiscountB, index) }}</span>
              </template>
            </a-table-column>
          </a-table>
        </template>
      </div>
      <!-- 批准报价 -->
      <QuoteApproveModal
        :reportInfo="reportInfo"
        :info="info"
        :visible.sync="approveVisible"
        @closeDrawer="closeDrawer"
      />
      <!-- 更改订单类型 -->
      <ChangeOrderType
        :orderInfo="info"
        :info="reportInfo"
        :selectedServiceType="selectedServiceType"
        :visible.sync="changeOrderTypeVisible"
        @addItem="handleAddTypeItem"
      />
      <!-- 新增报价选项 -->
      <AddQuoteOptionModal
        :orderInfo="info"
        :info="reportInfo"
        :selectedServiceType="selectedServiceType"
        :visible.sync="quoteOptionVisible"
        @addItem="handleAddPriceItem"
      />
      <!-- 添加价格 -->
      <AddPriceModal :defaultInfo="priceFormData" :visible.sync="priceVisible" @refresh="handleRefreshSelectItem" />
    </a-spin>
    <!-- footer -->
    <template v-if="!isPdf" v-slot:footer>
      <a-space :size="10" class="fl-r">
        <!-- 创建报价 -> 等待报价 -->
        <a-button
          type="primary"
          v-if="$role('order_orderInfo_createQuote') && info.status === 1"
          :disabled="createQuoteBtnDisable"
          @click="handleCreateQuote"
        >
          {{ $t('orderInfo.btn_submit_quote') }}
        </a-button>
        <!-- 拒绝 -> 待确认报价 -->
        <a-button
          class="neo-btn-primary"
          v-if="$role('order_orderInfo_quoteRefuse') && info.status === 2"
          @click="handleRefuse"
        >
          {{ $t('refuse') }}
        </a-button>
        <!-- 批准报价 -> 待确认报价 -->
        <a-button
          type="primary"
          v-if="$role('order_orderInfo_quoteApprove') && info.status === 2"
          @click="handleApprove"
        >
          {{ $t('orderInfo.btn_quote_approve') }}
        </a-button>
      </a-space>
    </template>
  </NeoDrawerLayout>
</template>

<script>
import Cookies from 'js-cookie'
import numeral from 'numeral'
import { uuid, downloadBlob } from '@/utils/utils'
import { Icon } from 'ant-design-vue'
import NeoTag from '@/components/common/NeoTag'
import QuoteApproveModal from './ApproveModal'
import ChangeOrderType from './ChangeOrderType.vue'
import AddQuoteOptionModal from './AddQuoteOptionModal'
import AddPriceModal from './AddPriceModal'
import moment from 'moment'
import QuotationItem from './QuotationItem'
const { localeDict, storageKey, wordReportRangeColumn } = window.$g
import floatObj from '@/utils/ASMD.js'

export default {
  name: 'Quatation',
  components: {
    NeoTag,
    QuoteApproveModal,
    ChangeOrderType,
    AddQuoteOptionModal,
    AddPriceModal,
    QuotationItem,
  },
  props: {
    // 订单详情
    info: {
      type: Object,
      default: () => ({}),
    },
    // 展示方式是否为pdf（用于pdf导出）
    isPdf: {
      type: Boolean,
      default: false,
    },
    // 批量导出的id
    ids: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localeDict,
      pdfLoading: false,
      loading: false,
      // 报价单详情
      reportInfo: {},
      dataSource: [],
      batchDataSource: [],
      containsProjectManageCost: false,
      projectManageRatio: 0,
      // wwc
      wwcColumn: [],
      wwcDataSource: [],
      wwcDataSourceDiscount: [],

      // 批量版本wwc根据produceVersion
      wwcDataSourceVersionA: [],
      wwcDataSourceDiscountA: [],
      wwcDataSourceVersionB: [],
      wwcDataSourceDiscountB: [],

      // 批准报价
      approveVisible: false,
      // 新增报价选项
      quoteOptionVisible: false,
      // price表单数据
      priceFormData: {},
      // price visible
      priceVisible: false,
      moment,

      // 更改订单类型
      changeOrderTypeVisible: false,
    }
  },
  watch: {
    dataSource: {
      handler: function (arr) {
        arr?.forEach((item) => {
          if (item.selectivePriceList?.length === 1) {
            // 当长度只有1时，自动选择第一个值
            item.priceType = item.selectivePriceList[0].id
          }
        })
      },
      deep: true, //深度监听
    },
    proAdminCost(price) {
      const target = this.dataSource.find((item) => item.key === 'project_manage_text')
      target && (target.price = price)
    },
    containsProjectManageCost(v) {
      if (v) {
        //  添加项目管理行
        if (this.dataSource.every((item) => !item.key)) {
          this.dataSource.push(this.projectAdmin)
        }
      } else {
        // 删去项目管理行
        this.dataSource.forEach((item, idx) => {
          if (item.key === 'project_manage_text') {
            this.dataSource.splice(idx, 1)
          }
        })
      }
      this.projectManageRatio = 0
    },
  },
  computed: {
    selfs() {
      return this
    },
    // 当price存在null情况就不能创建报价
    createQuoteBtnDisable() {
      const flag = this.dataSource
        .filter((v) => v.key !== 'project_manage_text')
        .find((v) => !this.$is.Defined(v.selectivePriceList) || !v.selectivePriceList.length || !v.priceType)
      if (flag || !this.dataSource.length) {
        return true
      }
      return false
    },

    // 报价单中已选的服务类型
    selectedServiceType() {
      const ids = this.dataSource.map((v) => v.serviceType)
      return [...new Set(ids)]
    },
    restRadio() {
      return numeral(this.projectManageRatio).divide(100).value()
    },
    proAdminCost() {
      const proAdminCost = floatObj.multiply(this.getTableTotalPrice(), this.restRadio)
      console.error('proAdminCost :>> ', proAdminCost)
      const proAdminCost_length = String(proAdminCost).split('.')[1]?.length
      console.error('proAdminCost_length :>> ', proAdminCost_length)
      return proAdminCost
    },
    projectAdmin() {
      return {
        key: 'project_manage_text',
        amount: 1,
        currency: '1',
        // status: 999,
        id: Math.random().toString(36).substr(-8),
        orderId: '1435164939401863170',
        price: this.proAdminCost,
        priceType: 0,
        remark: null,
        selectivePriceList: [404],
        serviceKind: 3,
        serviceLabel: this.$t('newOrder.project_manage_text'),
        serviceType: '3',
        sourceCode: null,
        targetCode: null,
        unit: null,
      }
    },
  },
  mounted() {
    if ('id' in this.info) {
      this.getReportInfo(this.info.id)
    } else {
      if (this.ids.length === 1) {
        this.getReportInfo(this.ids[0])
      } else {
        this.getBatchReportInfo(this.ids)
      }
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    projectManageRatioInput(e, ref) {
      if (!/^([0-9]{1,2}|100)$/.test(e)) {
        return
      } else {
        this.projectManageRatio = e
      }
    },
    noProjManText(text) {
      return text.key !== 'project_manage_text'
    },
    getWidth(w) {
      return this.isPdf ? `16.66%` : w
    },
    closeDrawer() {
      this.$emit('close')
    },

    // 根据版本获取wwc区间表头
    getWwcTableRangeByProduceVersion(version) {
      return wordReportRangeColumn.find((v) => v.produceVersion === version)?.range || []
    },

    // 添加单价
    handleAddPrice({ serviceType, targetCode }) {
      const { sourceCode, cusAgencyId } = this.reportInfo
      const { field, documentType, currency } = this.info
      this.priceVisible = true
      this.priceFormData = {
        agencyId: cusAgencyId,
        currency,
        serviceType,
        targetCode,
        sourceCode,
        field,
        documentType,
      }
    },

    /**
     * 表格数据转换
     * 1 按语言分类 整理数据
     * {
     *   zh-cn: [],
     *   en-us: []
     * }
     * 2 转换成真实数据
     * [
     *  {},
     *  {}
     * ]
     * */
    getWwcTableSource(data) {
      // 当只有一个单子的时候暂时用targetcode匹配多个用orderId匹配
      const langMap = data.reduce((acc, val) => {
        const k = this.ids.length < 2 ? val.targetCode : val.targetCode + '%%' + val.orderId
        if (acc[k]) {
          acc[k] = [...acc[k], val]
        } else {
          acc[k] = [val]
        }
        return acc
      }, {})
      return Object.entries(langMap).map(([lang, data]) => {
        const rangeMap = data.reduce((acc, val) => {
          acc[val.discountRange] = val.baseCount || 0
          return acc
        }, {})
        return {
          targetCode: lang.split('%%')[0],
          id: uuid(),
          ...rangeMap,
        }
      })
    },

    // 批量
    getWwcTableSourceBatch(data) {
      // 当只有一个单子的时候暂时用targetcode匹配多个用orderId匹配
      const langMap = data.reduce((acc, val) => {
        const k = val.sourceCode + '-' + val.targetCode + '%%' + val.orderId
        if (acc[k]) {
          acc[k] = [...acc[k], val]
        } else {
          acc[k] = [val]
        }
        return acc
      }, {})

      return Object.entries(langMap).map(([lang, data]) => {
        const rangeMap = data.reduce((acc, val) => {
          acc['orderCode'] = val.orderCode
          acc[val.discountRange] = val.baseCount || 0
          return acc
        }, {})

        return {
          sourceCode: lang.split('%%')[0].split('-')[0],
          targetCode: lang.split('%%')[0].split('-')[1],
          id: uuid(),
          ...rangeMap,
        }
      })
    },
    // 只用来返回统计的折扣字数
    getWwcTableSourceDiscount(data) {
      const langMap = data.reduce((acc, val) => {
        let k
        if (this.ids.length < 2) {
          k = val.sourceCode + '-' + val.targetCode
        } else {
          k = val.sourceCode + '-' + val.targetCode + '%%' + val.orderId
        }
        if (acc[k]) {
          acc[k] = [...acc[k], val]
        } else {
          acc[k] = [val]
        }
        return acc
      }, {})
      return Object.entries(langMap).map(([lang, data]) => {
        const rangeMap = data.reduce((acc, val) => {
          acc['orderCode'] = val.orderCode
          acc[val.discountRange] = val.discountCount || 0
          return acc
        }, {})
        return {
          sourceCode: lang.split('%%')[0].split('-')[0],
          targetCode: lang.split('%%')[0].split('-')[1],
          id: uuid(),
          ...rangeMap,
        }
      })
    },

    // 价格统计
    getPriceByAmount(index) {
      // 通过 priceType 计算
      if (this.info.status === 1) {
        const { priceType, selectivePriceList, amount = 0 } = this.dataSource[index]
        if (priceType && selectivePriceList && selectivePriceList.length) {
          const { price = 0 } = selectivePriceList.find((v) => v.id === priceType)
          // return numeral(price * amount).format('0.[00]')
          return numeral(price).multiply(amount).value()
        } else {
          return null
        }
      } else {
        // 直接计算
        const { price = 0, amount = 0 } = this.dataSource[index]
        // return numeral(price * amount).format('0.[00]')
        return numeral(price).multiply(amount).value()
      }
    },

    // table footer 价格合计
    getTableTotalPrice() {
      return numeral(
        this.dataSource.reduce((acc, val) => {
          // 通过 priceType 计算
          if (this.info.status === 1) {
            const { selectivePriceList, priceType, amount = 0 } = val
            if (priceType && selectivePriceList && selectivePriceList.length) {
              const { price = 0 } = selectivePriceList.find((v) => v.id === priceType)
              // acc = acc + Number(numeral(price * amount).format('0.[00]') || 0)
              acc = numeral(acc).add(numeral(price).multiply(amount).value()).value()
            }
          } else {
            const { price = 0, amount = 0 } = val
            // acc = acc + Number(numeral(price * amount).format('0.[00]') || 0)
            acc = numeral(acc).add(numeral(price).multiply(amount).value()).value()
          }
          return acc
        }, 0)
      ).value()
    },

    // table footer 价格合计（带项目管理费）
    getTableTotalPrice2() {
      return numeral(this.getTableTotalPrice()).add(this.proAdminCost).value()
    },

    // batch footer 价格合计
    getBatchDataSourceTotalPrice() {
      return numeral(
        this.batchDataSource.reduce((acc, val) => {
          acc = numeral(acc).add(val.quotePrice).value()
          return acc
        }, 0)
      ).format('0.[00]')
    },

    // 币种单位
    currencyUnit(currency) {
      if (currency || this.$is.Defined(this.info.currency) || this.$is.Defined(this.reportInfo.currency)) {
        const unit =
          this.$store.getters['app/getDictLabel'](
            'CURRENCY',
            currency || this.info.currency || this.reportInfo.currency
          ) || ''
        return unit.split(';')
      } else {
        return false
      }
    },

    // 统计wwc
    getWwcCount(idx) {
      const count = this.wwcColumn.reduce((acc, key) => {
        acc = acc + (Number(this.wwcDataSourceDiscount[idx][key]) || 0)
        return acc
      }, 0)
      return numeral(count).format('0.[00]')
    },

    // 批量版本统计wwc
    getBatchWwcCount(version, discount, idx) {
      const wwcColumn = this.getWwcTableRangeByProduceVersion(version)
      const count = wwcColumn.reduce((acc, key) => {
        acc = acc + (Number(discount[idx][key]) || 0)
        return acc
      }, 0)
      return numeral(count).format('0.[00]')
    },

    // 新增类型项
    handleAddTypeItem(values) {
      this.dataSource = this.dataSource.filter((item) => !item.bacth)
      this.dataSource.push(...values)
    },

    // 新增报价项
    handleAddPriceItem(item) {
      this.dataSource.push(item)
    },

    // 删除报价项
    handeDeletePriceItem(idx) {
      this.dataSource.splice(idx, 1)
    },

    // 添加完报价项后刷新下拉项
    async handleRefreshSelectItem() {
      try {
        this.loading = true
        const info = await this.$http({
          key: 'orderProcessQuoteInfo',
          params: { id: this.info.id },
        })
        const { priceList } = info

        priceList.forEach((v) => {
          this.dataSource.forEach((d) => {
            if (d.id === v.id) {
              d.selectivePriceList = v.selectivePriceList
            }
          })
        })

        this.loading = false
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 获取单条报价单
    async getReportInfo(id) {
      try {
        this.loading = true
        const info = await this.$http({
          key: 'orderProcessQuoteInfo',
          params: { id },
        })

        this.loading = false
        this.loading = false
        this.reportInfo = info
        this.wwcDataSource = this.getWwcTableSource(this.reportInfo.wwcList)
        this.wwcDataSourceDiscount = this.getWwcTableSourceDiscount(this.reportInfo.wwcList)
        this.dataSource = info.priceList.map((v) => ({
          ...v,
          priceType: v.priceType || undefined,
        }))

        const wordColumn = wordReportRangeColumn.find((item) => {
          return item.produceVersion === (this.info?.produceVersion || info.produceVersion)
        })
        this.wwcColumn = wordColumn?.range || []
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 获取批量报价单
    async getBatchReportInfo(ids) {
      try {
        this.loading = true
        const info = await this.$http({
          key: 'orderProcessQuoteBatchInfo',
          params: {
            ids: ids.join(','),
          },
        })
        this.loading = false
        this.reportInfo = info

        const { wwcList, orderList } = info
        // 给wwc添加version
        const wwcListWithVersion = wwcList.map((v) => ({
          ...v,
          produceVersion: orderList.find((o) => o.id === v.orderId)?.produceVersion,
        }))

        const wwcListA = wwcListWithVersion.filter((v) => v.produceVersion === 1)
        const wwcListB = wwcListWithVersion.filter((v) => v.produceVersion === 2)

        this.wwcDataSourceVersionA = this.getWwcTableSourceBatch(wwcListA)
        this.wwcDataSourceDiscountA = this.getWwcTableSourceDiscount(wwcListA)

        this.wwcDataSourceVersionB = this.getWwcTableSourceBatch(wwcListB)
        this.wwcDataSourceDiscountB = this.getWwcTableSourceDiscount(wwcListB)

        this.batchDataSource = info.orderList
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 创建报价
    async handleCreateQuote() {
      try {
        this.loading = true
        await this.$http('orderProcessQuote', {
          id: this.info.id,
          containsProjectManageCost: this.containsProjectManageCost,
          projectManageRatio: this.projectManageRatio,
          settlementServiceType: this.dataSource.find((v) => v.bacth)
            ? this.dataSource.find((v) => v.bacth).serviceType
            : '',
          priceList: this.dataSource
            .filter((v) => v.key !== 'project_manage_text')
            .map((v) => ({
              amount: v.amount,
              id: v.new ? undefined : v.id,
              serviceType: v.serviceType,
              priceType: v.priceType,
              targetCode: v.targetCode,
              wordAmount: v.wordAmount,
              remark: v.remark,
            })),
        })
        this.loading = false
        // 刷新订单info
        this.$bus.$emit('_refresh_order_info_')
        // 关闭抽屉
        this.$emit('close')
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },

    // 拒绝报价
    handleRefuse() {
      this.$confirm({
        title: this.$t('orderInfo.quote_confirm_title'),
        icon: (h) => h(Icon, { props: { type: 'exclamation-circle', theme: 'filled' } }),
        content: this.$t('orderInfo.quote_confirm_info'),
        okText: this.$t('orderInfo.quote_confirm_cancelText'),
        cancelText: this.$t('orderInfo.quote_confirm_okText'),
        onCancel: async () => {
          try {
            await this.$http('orderProcessWithdraw', { id: this.info.id })
            // 刷新订单info
            this.$bus.$emit('_refresh_order_info_')
            // 关闭抽屉
            this.$emit('close')
          } catch (err) {
            this.$httpNotify(err)
          }
        },
      })
    },

    // 批准报价
    handleApprove() {
      this.approveVisible = true
    },

    handleChangeOrderType() {
      this.changeOrderTypeVisible = true
    },

    // 新增报价选项
    handleQuoteOption() {
      this.quoteOptionVisible = true
    },

    // 导出报价单
    async handleExport() {
      try {
        let name
        if (this.ids.length <= 1) {
          name = `${this.info.name}_${this.$t('orderInfo.quote')}`
        } else {
          name = `${this.info.cusAgencyName}_${this.$t('orderInfo.quote')}_${moment().format('YYYYMMDDHHmm')}`
        }
        this.pdfLoading = true
        const pdf = await this.$http(
          'ossCmdHtmlToPdf',
          {
            oriUrl: 'Kingsoft/pdf-quotation',
            token: Cookies.get(storageKey.TOKEN),
            pdfInfoList: [
              {
                ids: this.info.id ? this.info.id : this.ids.join(','),
                pdfName: '',
              },
            ],
          },
          {
            responseType: 'blob',
          }
        )
        this.pdfLoading = false
        name = name + `.pdf`
        downloadBlob(name, 'application/pdf;charset-UTF-8', pdf)
      } catch (err) {
        this.pdfLoading = false
        this.$httpNotify(err)
      }
    },
    async func() {
      try {
        const params = {}
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('name', params)
      } catch (err) {
        this.$httpNotify(err)
      }
    },
  },
}
</script>
